import { useState } from "react"

export function Login(props){
    let [usuario,setUsuario] = useState("admin");
    let [pwd, setPwd] = useState("qwerty");

    async function loguear(){
        let url = `https://www.practicasudo.isaerpweb.com/usuario/loguear?email=${usuario}&pwd=${pwd}`;
        let resp = await fetch(url);
        let dato = await resp.json();
        if(dato.Id === 0)
          alert("Datos incorrectos, intenta de nuevo");
        else
          props.loginCorrecto(dato);
    }

    return(
        <div>
            <table>
                <tr>
                    <td>Usuario</td>
                    <td><input value={usuario} onChange={(e)=> setUsuario(e.target.value)} /></td>
                </tr>
                <tr>
                    <td>Contraseña</td>
                    <td><input type="password" value={pwd} onChange={(e)=> setPwd(e.target.value)} /></td>
                </tr>
                <tr>
                    <td></td>
                    <td><button className="btn btn-sm btn-outline-success" onClick={loguear}>Iniciar sesion</button></td>
                </tr>
            </table>
        </div>
    )
}