import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export function Articulos(props){
    let [nombre, setNombre] = useState("");
    let [articulos, setArticulos] = useState([]);
    let [mostrar, setMostrar] = useState(false);
    
    async function cargar(){
        let url="https://www.practicasudo.isaerpweb.com/articulo/listar?filtro=" + nombre;
        let resp = await fetch(url);
        let dato = await resp.json();
        setArticulos(dato);
    }
    function agregar(){
        setMostrar(true);
    }
    return (
        <div>
            <h5>Articulos</h5>
            <table>
                <tr>
                    <td>Nombre</td>
                    <td><input value={nombre} onChange={(e)=> setNombre(e.target.value)}/></td>
                    <td><button className="btn btn-sm btn-outline-success" onClick={cargar}><img src="/imagenes/ok.png" /></button></td>
                    <td><button className="btn btn-sm btn-outline-success" onClick={agregar}><img src="/imagenes/agregar.png" /></button></td>
                </tr>
            </table>
            <table className="table table-striped table-sm table-hover">
                <thead>
                    <tr>
                        <th style={{width:150}}>Codigo</th>
                        <th>Nombre</th>
                        <th className="celdaImg"></th>
                        <th className="celdaImg"></th>
                    </tr>
                </thead>
                <tbody>
                    {articulos.map((d,i) => 
                     <tr key={i}>
                        <td>{d.C}</td>
                        <td>{d.N}</td>
                        <td><img src="/imagenes/lapiz.png" title="editar" /></td>
                        <td><img src="/imagenes/borrar.png" title="eliminar" /></td>
                    </tr>)}
                </tbody>
            </table>
            <Modal isOpen={mostrar} toggle={()=>setMostrar(false)}>
                <ModalHeader>
                    <h5>Nuevo articulo</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <span>Codigo</span><br />
                        <span>Nombre</span><br />
                        <span>Costo</span><br />
                        <span>Precio</span><br />
                        <span>Departamento</span><br />
                        <span>Proveedor</span><br />
                    </div>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </div>
    )
}