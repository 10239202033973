import './App.css';
import { Articulos } from './Articulos';
import {Login} from './Login';
import { useState } from 'react';

function App() {
  let [logueado, setLogueado] = useState(false);
  let [usuario, setUsuario] = useState({Id:0,Nombre:''});

  function loginCorrecto(u){
    setLogueado(true);
    setUsuario(u);
  }
  return (
    <div>
      {!logueado && <Login loginCorrecto={loginCorrecto} /> }
      {logueado  && <Articulos usuario={usuario.Id} /> }
    </div>
  );
}

export default App;
